.add-comp-btn {
  width: 30%;
  margin-bottom: 2em;
  min-width: 176px;
  max-width: 176px;
  /* background: #4E9CAF; */
  background: #3b7fff;
  font-size: 15px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  white-space: wrap;
}

.main-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.disabled-buttons button {
  opacity: 0.5; /* Reduce opacity to gray out the buttons */
  pointer-events: none; /* Disable pointer events to prevent clicks */
  /* Add any other styling you want for disabled buttons */
}

/* .feedback-title{
    margin-top: 2em;
    margin-left: 2em;
} */

h2 {
  font-family: Noto Sans KR, sans-serif;
}

.feedback-container {
  margin-top: 2em;
  width: fit-content;
  margin-left: 3em;
}

.predicted-score-title {
  margin-top: 1.5em;
}

/* .predicted-score-content{
    margin-left: 1em;
} */

.feedback-total {
  font-weight: 700;
}

.option-needed-grade {
  font-weight: 700;
}

.option-name {
  font-weight: 700;
}

.fallen-behind-goal-txt {
  color: red;
}

.better-than-goal-txt {
  color: green;
}

.on-track-with-goal-txt {
  color: green;
}

.course-completed-txt {
  /* color: #71d955; */
  color: green;
  font-size: 1.5em;
}

.main {
  position: relative;
}

.note {
  font-weight: bold;
}

.insert-rem-components-txt {
  margin-top: 3em;
  font-size: 1em;
}
