#courses {
  list-style: none;
  font-size: x-large;
  text-decoration: none;
  color: black;
}

.course-input {
  width: 100px;
  height: 10px;
  margin-left: 1.25em;
  padding: 0.5rem;
}

.course-span {
  display: flex;
  align-items: center;
}

a {
  font-size: medium;
  text-decoration: none;
}

a:hover {
  /* color: orange; */
}

.sem-course-block {
  position: relative;
  /* min-width: 600px; */
  background: white;
  border-bottom: 1px solid black;
  padding-left: 1em;
  padding-bottom: 1em;
}

.trash-can {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 0.5em;
}

.trash-can:hover {
  color: red;
}

.course {
  min-width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn {
  /* background-color: inherit; */
  background-color: RGB(95, 230, 46);
  border-radius: 2rem;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: white;
}

.btn:hover {
  background-color: green;
}

.view-details-btn {
  border: 1px solid grey;
  background-color: #4e9cff;
  color: white;
  cursor: pointer;
  margin-left: 1rem;
  border-radius: 10%;
  height: 70%;
  font-size: 50%;
}

.view-details-btn:hover {
  box-shadow: 2px 2px;
  background-color: #3496eb;
}

.delete-course-btn {
  border: 1px solid grey;
  background-color: red;
  margin-left: 0.5rem;
  border-radius: 10%;
  height: 70%;
  font-size: 50%;
}

.delete-course-btn:hover {
  background-color: red;
  cursor: pointer;
  box-shadow: 0.5px 0.5px;
  /* background-color: #cc2727; */
}

/* h2{
    font-family: cursive;
} */

.del-sem-btn {
  border-radius: 2rem;
  background-color: RGB(230, 46, 64);
  color: white;
  cursor: pointer;
}

.del-sem-btn:hover {
  background-color: red;
}

@media screen and (max-width: 442px) {
  /* h2 {
    font-family: cursive;
    font-size: 1em;
  } */

  .course-input {
    width: 80px;
    height: 7px;
    margin-left: 1.25em;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 360px) {
  /* h2 {
    font-family: cursive;
    font-size: 0.8em;
  } */

  .course-input {
    width: 65px;
    height: 5px;
    margin-left: 1.25em;
    padding: 0.5rem;
  }
  a {
    font-size: 0.5em;
  }

  .no-courses {
    font-size: 0.8em;
  }
}
