.reset-pwd-form {
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7em;

}

.input-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 15em;
}

.btn.submit-button {
    margin-top: 1em;
    display: inline-block;
    width: 70px;
    height: 10px;
    background: #3b7fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: small;
    line-height: 8px;
    cursor: pointer;
}

.btn.submit-button:hover {
    background: #0057FB;;
    text-decoration: underline;
}

.new-pwd {
    padding: 1rem;
    font-weight: 500;
}

.pwd-input, .confirm-pwd-input {
    width: 15rem;
    height: 1.2rem;
    margin-bottom: 1rem;
    border-radius: 2%;
}

.confirm-pwd {
    padding: 1rem;

}